import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const LiveChatsHistory = () => {
  const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginateData, setPaginateData] = useState([]);
    useEffect(() => {
      fetch(`https://rubi.api.royeluniverse.com/api/admin/live/chat/all/conversation/view`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setPaginateData(data);
          setLoading(false);
        });
    }, []);
  
    const handlePage = (event) => {
      try {
        const pageNumber = event?.selected + 1;
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .get(
            `https://rubi.api.royeluniverse.com/api/admin/live/chat/pending/view?page=${pageNumber}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
      }
    };
    if (!(authUser?.live_chat)) {
      if (authUser?.live_chat === undefined) {
          return (
              <>
                  <div className="loader-css">
                      <RotatingSquare
                          visible={true}
                          height="100"
                          width="100"
                          color="#4fa94d"
                          ariaLabel="rotating-square-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                      />
                  </div>
              </>
          );
      } else {
          return (
              <>
                  <h1>unauthorized</h1>
              </>
          );
      }



  } else {
    if (loading) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
            <h6 class="page-title"> Pending Chats</h6>
            <div class="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
             
            </div>
          </div>
  
          <div class="row justify-content-center">
          
            <div class="col-md-12">
              <div class="card b-radius--10">
                <div class="card-body p-0">
                  <div class="table-responsive--sm table-responsive">
                    <table class="table table--light style--two mb-0">
                      <thead>
                        <tr>			
                          <th>S.N</th>
                          <th>Full Name</th>
                          <th>Create At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length !== 0 ? (
                          data.map((data, index) => {
                            if (data) {
                              return (
                                <SingleItem
                                  data={data}
                                  index={index}
                                  key={data._id}
                                  paginateData={paginateData}
                                ></SingleItem>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td className="text-muted text-center" colspan="100%">
                              Data not found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* paginateLinks */}
                <CustomPagination
                  data={paginateData}
                  handlePage={handlePage}
                ></CustomPagination>
                {/* paginateLinks */}
              </div>
            </div>
          </div>
        </>
      );
    }

  }
   
  };
  

export default LiveChatsHistory;