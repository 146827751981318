import React from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
const SingleItem = ({ data, index, userData }) => {
  return (
    <>
      <tr>
        <td data-label="User">
          <span className="fw-bold">{data?.user_name}</span>
          <br />
          <span className="small">
            {" "}
            <Link to={`/admin/users/details/${userData?._id}`}>
              <span>@</span>
              {userData?.uid}
            </Link>{" "}
          </span>
        </td>

        <td data-label="Login at">
          {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
