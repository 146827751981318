import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SingleItem from './SingleItem';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../../CustomPagination/CustomPagination";
import { AuthContext } from '../../../../../Contexts/AuthContext/AuthProvider';

const UserLoginHistory = () => {
    const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);
    
const {id} = useParams();
    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`https://rubi.api.royeluniverse.com/api/admin/user/view/login/history/${id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data);
                setPaginateData(data);
                setLoading(false);
            })
    }, []);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://rubi.api.royeluniverse.com/api/admin/user/view/login/history/${id}?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };
    
    const [userData, setUserData] = useState([]);


    useEffect(() => {
        fetch(`https://rubi.api.royeluniverse.com/api/admin/user/view/single/${id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setUserData(data.data);  
            })
    }, [])

    if (!(authUser?.manage_users)) {
        if (authUser?.manage_users === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {

        if(loading){
            return (
                <>
                  <div className="loader-css">
                    <RotatingSquare
                      visible={true}
                      height="100"
                      width="100"
                      color="#4fa94d"
                      ariaLabel="rotating-square-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </>
              );
        }
        return (
            <>
                <div className="bodywrapper__inner">
    
                    <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 className="page-title">User Login History</h6>
                        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                        </div>
                    </div>
    
                    <div className="row">
    
                        <div className="col-lg-12">
                            <div className="card b-radius--10 ">
                                <div className="card-body p-0">
    
                                    <div className="table-responsive--sm table-responsive">
                                        <table className="table table--light style--two">
                                            <thead>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Login at</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                            {data.length !== 0 ?
                                                data.map((data, index) => {
                                                    if (data) {
                                                        return (
                                                            <SingleItem data={data} index={index} key={data._id} userData={userData}></SingleItem>
                                                        );
                                                    }
                                                }) :
                                                <tr>
                                                    <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                </tr>}
    
    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                {/* paginateLinks */}
                                <CustomPagination
                                    data={paginateData}
                                    handlePage={handlePage}
                                > </CustomPagination>
                                {/* paginateLinks */}
                            </div>
                        </div>
    
    
                    </div>
    
    
                </div>
            </>
        );

    }
   
};

export default UserLoginHistory;