import image from "./images.png"
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
const ENDPOINT = "https://rubi.api.royeluniverse.com";

let socket;

const SingleUser = ({ data, singleChatView }) => {
    const [UserData, setUserData] = useState([])
    const [messages, setMessages] = useState({})

    useEffect(() => {
        if (data) {
            fetch(`https://rubi.api.royeluniverse.com/api/admin/user/view/single/email/${data?.user_email}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setUserData(data.data);
               
                })
        }

    }, [data])

    // socket section

    socket = io(ENDPOINT);

    useEffect(() => {
        if (typeof (data?.user_email) === "string") {
            socket.emit('join_room', data?.user_email);
            socket = io(ENDPOINT);
        }

    }, []);

    useEffect(() => {
        socket.on("recvice_message", (NewMessage) => {
            setMessages(NewMessage);
        });
    });
    if (UserData) {
        return (
            <>

                <li className="clearfix" onClick={() => singleChatView(UserData)}>

                    {UserData?.picture === null ?
                        <img src={image} alt="avatar" />
                        :
                        <img src={`${UserData?.picture}`} alt="avatar" />
                    }

                    <div className="live-chats-about">
                        <div className="name">{UserData?.fname} {UserData?.lname}</div>
                        {/* <div className="status">
                            <i className="fa fa-circle online">
                            </i> online
                        </div> */}
                        {messages?.recevierId === null
                            && messages?.senderId === UserData?._id ?
                            <div className="status">
                                {messages?.message}
                            </div>
                            :
                            ''}

                    </div>
                </li>

            </>
        );
    } else {
        const withOutAuthUser = { fname: data?.fname, lname: data?.lname, email: data?.user_email }
        return (
            <>

                <li className="clearfix" onClick={() => singleChatView(withOutAuthUser)}>
                    <img src={image} alt="avatar" />
                    <div className="live-chats-about">
                        <div className="name">{data?.fname} {data?.lname}</div>
                        {/* <div className="status">
                            <i className="fa fa-circle online">
                            </i> online
                        </div> */}
                        {messages?.recevier_email === null
                            && messages?.sender_email === data?.user_email ?
                            <div className="status">
                                {messages?.message}
                            </div>
                            :
                            ''}

                    </div>
                </li>

            </>
        );
    }

};

export default SingleUser;