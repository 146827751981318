import React from 'react';
import { Link } from 'react-router-dom';

const SingleItem = ({ index, data, handleDelete, paginateData }) => {
    return (
        <>
            <tr>
                <td data-label="S.N.">{ (parseFloat(index + 1) + (parseFloat(paginateData?.page) * parseFloat(paginateData?.limit))) - 10 }</td>
          
                <td data-label="Name">{data?.name}</td>
                <td data-label="Action">
                    <div className="button--group">
                        <Link to={`/admin/news/category/update/${data?._id}`} className="btn btn-sm btn-outline-primary"><i className="la la-pencil-alt"></i> Edit</Link>
                        <button className="btn btn-sm btn-outline-danger " onClick={()=>handleDelete(data?._id)} ><i className="la la-trash"></i> Remove</button>
                    </div>
                </td>
            </tr>

        </>
    );
};

export default SingleItem;