import React from 'react';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
const ChatsItem = ({ data }) => {

    if (data?.recevier_email !== null) {
        return (
            <>
                <li className="clearfix">
                    <div className="message-data text-end">
                        <span className="message-data-time d-block mb-1">{dateFormat(data?.createdAt, "dd mmm yyyy h:MM TT")}</span>
                        {/* <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar" /> */}
                        <div className="message other-message "> {data?.message} </div>
                    </div>

                </li>

            </>
        );

    } else {

        return (
            <>

                <li className="clearfix">
                    <div className="message-data">
                        {/* <span className="message-data-time">{data?.fname} {data?.lname}</span> */}
                        <span className="message-data-time">{dateFormat(data?.createdAt, "dd mmm yyyy h:MM TT")}</span>
                    </div>
                    {data?.attach !== null
                        ?
                        <>
                        
                        <Link to={`https://rubi.api.royeluniverse.com/${data?.attach}`} target="_blank" className="chat-attach-file text-danger d-block">
                           
                           <img src={`https://rubi.api.royeluniverse.com/${data?.attach}`} alt='' width='200px' height='auto' />
                        </Link>
                        <br />
                        </>
                        
                        :
                        ''
                    }
                    {data?.message !== ''
                        ?
                        <div className="message my-message">{data?.message}</div>
                        :
                        ''
                    }


                </li>

            </>
        );
    }


};

export default ChatsItem;