import React from 'react';
import { NavLink } from 'react-router-dom';

const SideBarMenu = ({ route, setMobileMenu }) => {
 
    return (
        <li className="sidebar-menu-item {{menuActive('admin.dashboard')}}">
            <NavLink to={route.path} className="nav-link " onClick={()=>setMobileMenu(true)}>
                <i className={route.icon}></i>
                <span className="menu-title">{route.name}</span>
            </NavLink>
        </li>
    );

};

export default SideBarMenu;