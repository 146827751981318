import React, { useState, useRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import JoditEditor from 'jodit-react';
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';
import { RotatingSquare } from 'react-loader-spinner';

const NewsAdd = () => {
    const { authUser } = useContext(AuthContext);
    const DisRef = useRef(null)
    const [Dis, setDisContent] = useState('')


    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();


    const [userImage, setUserImage] = useState('');

    const handleImage = (e) => {
        setUserImage(e.target.files[0])
    }

    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        const userData = { ...dataVulue, auth_id:authUser?._id, image: userImage, news:Dis, };
        if (userData.image === '') {
            toast.error(`please choose your image`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
        }else if(userData?.category === '' || userData?.category ===  undefined){
            toast.error(`please select category `, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
        } else if(userData?.news === ''){
            toast.error(` Description field required`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
        } else {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            };
            axios.post(`https://rubi.api.royeluniverse.com/api/admin/news/store`, userData, config)
                .then(response => {
                    event.target.reset();
                    toast.success(`${response?.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");
                }).catch((error) => {
                    refSubmitDis.current.removeAttribute("disabled");
                });

        }

    }

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }


    const [data, setData] = useState([]);


    useEffect(() => {
        fetch(`https://rubi.api.royeluniverse.com/api/admin/news/category/view`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data?.data)
            })
    }, []);


    if (!(authUser?.manage_section)) {
        if (authUser?.manage_section === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }
    
    
    
    } else {
        return (
            <>
                <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                    <h6 className="page-title">News add</h6>
                    <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                    </div>
                </div>
    
                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-30">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmitData}>
                                    <div className="row">
                                        <div className=" col-md-8 ">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="heading" className="required">Title</label>
                                                    <input type="text" className="form-control" name="title" onBlur={handleInputBlur} placeholder='Title' required id="heading" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                            <div className="form-group">
                                                    <label htmlFor="role"  className="required"> Category</label>
                                                  {
                                                    <select name="category" className="form-control" value={dataVulue?.category} onBlur={handleInputBlur} required>
                                                        <option selected>
                                                            Select Now
                                                        </option>
                                                        {data.map((data, idx) => {
                                                            return (
                                                                <option key={data?._id} value={data?.name}>
                                                                    {data?.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="heading" className="required">Short Description</label>
                                                    <textarea name='dis' onBlur={handleInputBlur} required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="image-upload">
                                                    <div className="thumb">
                                                        <div className="avatar-edit">
                                                            <input type="file" onChange={handleImage} className="profilePicUpload" name="image" id="profilePicUpload0" accept=".jpg,.jpeg,.png" />
                                                            <label htmlFor="profilePicUpload0" className="bg--primary">Choose Image</label>
                                                            <small class="mt-2  ">Supported files: <b>jpeg, jpg, png.</b> Image will be resized into   (width: 1200px * height: 800px)  </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="button_one_url" className="required"> Description</label>
                                                <JoditEditor
                                                    ref={DisRef}
                                                    value={Dis}
                                                    height="600"
                                                    onChange={(newContent) => setDisContent(newContent)}
                                                />
                                            </div>
                                        </div>
                                        
    
                                    </div>
    
                                    <div className="form-group">
                                        <button ref={refSubmitDis} type="submit" className="btn btn-primary w-100 h-45">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }
  

   
};

export default NewsAdd;