import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import { RotatingSquare } from "react-loader-spinner";
import dateFormat from "dateformat";
const AprovedNow = () => {
  const { authUser } = useContext(AuthContext);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState([]);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    fetch(`https://rubi.api.royeluniverse.com/api/admin/loan/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, [updateData]);

  useEffect(() => {
    if (data?.user_id) {
      fetch(
        `https://rubi.api.royeluniverse.com/api/admin/user/view/single/${data?.user_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [data]);

  const refSubmitDis = useRef();

  const handleAccept = (id) => {
    refSubmitDis.current.setAttribute("disabled", true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .put(
        `https://rubi.api.royeluniverse.com/api/admin/loan/accept/${id}`,
        config
      )
      .then((response) => {
        toast.success(`${response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setupdateData(response?.data);
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        refSubmitDis.current.removeAttribute("disabled");
      });
  };


  const handleReject = (id) => {
    fetch(`https://rubi.api.royeluniverse.com/api/admin/loan/reject/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setupdateData(data);
        toast.error(`${data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => console.log(error));
  };

  if (!authUser?.loan) {
    if (authUser?.loan === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    return (
      <>
        <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 class="page-title">
            {" "}
            {userData?.fname} {userData?.lname} requested loan {data?.amount}{" "}
          </h6>
          <div class="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins"></div>
        </div>

        <div className="row mb-none-30 justify-content-center">
          <div className="col-xl-6 col-md-6 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="mb-20 text-muted">Loan Details</h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Date
                    <span className="fw-bold">
                      {dateFormat(data.createdAt, "d-m-yyyy h:MM:ss TT")}
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Full Name
                    <span className="fw-bold"> {data?.fname}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Email
                    <span className="fw-bold"> {data?.email}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Phone Number
                    <span className="fw-bold">{data?.phone} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Street
                    <span className="fw-bold">{data?.street} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    City
                    <span className="fw-bold">{data?.city} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    State
                    <span className="fw-bold">{data?.state} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Zip code
                    <span className="fw-bold">{data?.zip_code} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Nominee name
                    <span className="fw-bold">{data?.nominee_name} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Nominee phone number
                    <span className="fw-bold">{data?.nominee_phone} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Amount
                    <span className="fw-bold">{data?.amount}$ </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Nominee ID
                    <span className="fw-bold">
                      <a
                        href={`https://rubi.api.royeluniverse.com/${data?.nominee_ID}`}
                        target="_blank"
                        className="me-3"
                        alt=""
                      >
                        <i className="fa fa-file"></i> Attachment
                      </a>
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Applicant ID
                    <span className="fw-bold">
                      <a
                        href={`https://rubi.api.royeluniverse.com/${data?.applicant_ID}`}
                        target="_blank"
                        className="me-3"
                        alt=""
                      >
                        <i className="fa fa-file"></i> Attachment
                      </a>
                    </span>
                  </li>
                     
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Status
                    {data.status === 0 ? (
                      <span className="badge badge--warning text-warning">
                        Pending
                      </span>
                    ) : (
                      ""
                    )}
                    {data.status === 1 ? (
                      <span className="badge badge--success text-success">
                        Approved
                      </span>
                    ) : (
                      ""
                    )}
                    {data.status === 2 ? (
                      <span className="badge badge--danger text-danger">
                        Rejected
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
         
          <div className="col-xl-6 col-md-6 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="mb-20 text-muted">User Details</h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Date
                    <span className="fw-bold">
                      {dateFormat(userData?.createdAt, "d-m-yyyy h:MM:ss TT")}
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Username
                    <span className="fw-bold">
                      <Link to={`/admin/users/details/${userData?._id}`}>
                        {userData?.fname} {userData?.lname}
                      </Link>
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Email
                    <span className="fw-bold"> {userData?.email}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Phone Number
                    <span className="fw-bold">{userData?.phone} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Street
                    <span className="fw-bold">{userData?.street} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    City
                    <span className="fw-bold">{userData?.city} </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    State
                    <span className="fw-bold">{userData?.state} </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Zip code
                    <span className="fw-bold">{userData?.zip_code} </span>
                  </li>
                </ul>
                <div className="row mt-4">
                  <div className="col-md-12">
                    {data.status === 0 ? (
                      <>
                        <button onClick={()=>handleAccept(data?._id)} ref={refSubmitDis}
                          className="btn btn-sm btn-outline-success ms-1 confirmationBtn"
                        >
                          Approve
                        </button>
                        <button onClick={()=>handleReject(data?._id)}
                          className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
                        >
                          Reject
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>

        {/* <div class="row mb-none-30 justify-content-center">
          <div class="col-xl-5 col-md-6 mb-30">
            <div class="card b-radius--10 overflow-hidden box--shadow1">
              <div class="card-body">
                <h5 class="mb-20 text-muted">
                  Loan {data?.status === 0 ? "pending" : ""}{" "}
                  {data?.status === 1 ? "Aproved" : ""}{" "}
                  {data?.status === 2 ? "Reject" : ""}
                </h5>

                <div className="row">
                  <div className="col-lg-12 col-md-12 mb-30">
                    <div className="card">
                      <div className="card-body">
                        {data?.status === 0 ? (
                          <form onSubmit={handleSubmitData}>
                            <div className="row">
                              <div className=" col-md-12 ">
                                <div className="form-group">
                                  <label htmlFor="heading" className="required">
                                    Percentage
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="percentage"
                                    onBlur={handleInputBlur}
                                    placeholder="Percentage"
                                    required
                                    min="0"
                                    id="heading"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <label htmlFor="heading" className="required">
                                    Expired Time
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="expired_time"
                                    onBlur={handleInputBlur}
                                    placeholder="Title"
                                    required
                                    id="heading"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <button
                                ref={refSubmitDis}
                                type="submit"
                                className="btn btn-primary w-100 h-45"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        ) : (
                          <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              Status
                              {data.status === 1 ? (
                                <span class="badge badge--success text-success">
                                  Approved
                                </span>
                              ) : (
                                ""
                              )}
                              {data.status === 2 ? (
                                <span class="badge badge--danger text-danger">
                                  Rejected
                                </span>
                              ) : (
                                ""
                              )}
                              {data.status === 3 ? (
                                <span class="badge badge--info text-info">
                                  Loan Payment Completed
                                </span>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-7 col-md-6 mb-30">
            <div class="card b-radius--10 overflow-hidden box--shadow1">
              <div class="card-body">
                <h5 class="card-title mb-50 border-bottom pb-2">
                  Loan Details
                </h5>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6>Full Name</h6>
                    <p>
                      {userData?.fname} {userData?.lname}
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6>Amount</h6>
                    <p>{data?.amount} $</p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6>Reason</h6>
                    <p>{data?.reason} </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
};

export default AprovedNow;
