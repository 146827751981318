import React, { useContext, useEffect, useRef, useState } from 'react';
import SingleItem from './SingleItem';
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';
import { DatePicker } from "antd";
import dateFormat from "dateformat";
import { toast } from 'react-toastify';
const KYCPendingUsers = () => {
    const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);

    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`https://rubi.api.royeluniverse.com/api/admin/user/view/kyc/pending`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
                setPaginateData(data);
                setLoading(false);
            })
    }, []);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://rubi.api.royeluniverse.com/api/admin/user/view/kyc/pending?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };

    
    const [dateValue, setDateValue] = useState([]);
    const [dateValueShow, setDateValueShow] = useState([]);

    const refSubmitDis = useRef();

    const handleSubmitData = event => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const InpuData = Object.fromEntries(formData);

        refSubmitDis.current.setAttribute("disabled", true);
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.get(`https://rubi.api.royeluniverse.com/api/admin/user/view/kyc/pending/filter?name=${InpuData?.name}&uid=${InpuData?.uid}&email=${InpuData?.email}&start_date=${dateValue[0]}&end_date=${dateValue[1]}`, config)
            .then(response => {
                toast.success(`${response?.data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setData(response.data.data)
                refSubmitDis.current.removeAttribute("disabled");
            }).catch((error) => {
                refSubmitDis.current.removeAttribute("disabled");
            });

    }


    const { RangePicker } = DatePicker;

    const Time = () => {

        return (
            <>
                <RangePicker
                    value={dateValueShow}
                    onChange={(e) => {
                        setDateValue(e.map(item => {
                            return dateFormat(item, "isoUtcDateTime")
                        }));
                        setDateValueShow(e);
                    }}
                />
            </>
        );
    };



    if (!(authUser?.manage_users)) {
        if (authUser?.manage_users === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
    
            return (
                <>
                    <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 class="page-title">KYC Pending Users</h6>
                        
                    </div>
    
                    <div class="row">
                        <div class="col-lg-12">
                        <div className="show-filter mb-3 text-end">
                                    <button type="button" className="btn btn-outline--primary showFilterBtn btn-sm"><i className="las la-filter"></i> Filter</button>
                                </div>
                                <div className="card responsive-filter-card mb-4">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmitData}>
                                            <div className="d-flex flex-wrap gap-4">
                                                <div className="flex-grow-1">
                                                    <label>First Name</label>
                                                    <input type="text" name="name" className="form-control" id="search"  />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <label>UID</label>
                                                    <input type="text" name="uid" className="form-control" id="search"  />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <label>Email</label>
                                                    <input type="email" name="email" className="form-control" id="search" />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <label>Date</label>
                                                    <br />
                                                    <Time className="datepicker-here form-control" />
                                                    {/* <input name="date" type="text"  placeholder="Start date - End date" autocomplete="off" value="" id="date" /> */}
                                                </div>
                                                <div className="flex-grow-1 align-self-end">
                                                    <button ref={refSubmitDis} className="btn btn-primary w-100 h-45"><i className="fas fa-filter"></i> Filter</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            <div class="card b-radius--10 ">
                                <div class="card-body p-0">
                                    <div class="table-responsive--md  table-responsive">
                                        <table class="table table--light style--two">
                                            <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Password</th>
                                                    <th>Country</th>
                                                    <th>Joined At</th>
                                                    <th>Balance</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length !== 0 ?
                                                    data.map((data, index) => {
                                                        if (data) {
                                                            return (
                                                                <SingleItem data={data} index={index} key={data._id} paginateData={paginateData}></SingleItem>
                                                            );
                                                        }
                                                    }) :
                                                    <tr>
                                                        <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                    </tr>}
    
    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
    
                                {/* paginateLinks */}
                                <CustomPagination
                                    data={paginateData}
                                    handlePage={handlePage}
                                > </CustomPagination>
                                {/* paginateLinks */}
                            </div>
                        </div>
    
                    </div>
                </>
            );
        }

    }
   

};

export default KYCPendingUsers;