import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import { RotatingSquare } from 'react-loader-spinner';


const MiningEdit = () => {
    const { authUser } = useContext(AuthContext);
    const {id} = useParams();
    const [data, setData] = useState([]);
    const [updateData, setupdateData] = useState([]);
    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();
  

    useEffect(() => {
        fetch(`https://rubi.api.royeluniverse.com/api/admin/mining/view/${id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
            })
    }, [updateData])

 
    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.put(`https://rubi.api.royeluniverse.com/api/admin/mining/update/${id}`, dataVulue, config)
            .then(response => {
                event.target.reset();
                toast.success(`${response?.data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setupdateData(response?.data)
                refSubmitDis.current.removeAttribute("disabled");
            }).catch((error) => {
                refSubmitDis.current.removeAttribute("disabled");
            });

    }

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }

    if (!(authUser?.mining)) {
        if (authUser?.mining === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        return (
            <>
                <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                    <h6 className="page-title">Mining Update</h6>
                    <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                    </div>
                </div>
    
                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-30">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmitData}>
                                <div className="row">
                                    
                                <div className="col-md-12 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Mining Type</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="mining_type" id="role" required>
                                                <option selected disabled>select</option>
                                                <option value="bitcoin"> Bitcoin Mining </option>
                                                <option value="gold"> Gold Mining </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Promotion Name</label>
                                            <input type="text" className="form-control" name="promotion_name" onBlur={handleInputBlur} placeholder='promotion name'  id="heading" defaultValue={data?.promotion_name} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Principal</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="principal" id="role" >
                                                <option selected disabled>select</option>
                                                <option value="1"> Returns </option>
                                                <option value="2"> Included </option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Period Time</label>
                                            <input type="number" className="form-control" name="period" onBlur={handleInputBlur} placeholder='period '  id="heading" min='1' defaultValue={data?.period} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Period Type</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="period_type" id="role" >
                                                <option selected disabled>select</option>
                                                <option value="Hours">Hours</option>
                                                <option value="Day">Day</option>
                                                <option value="Months">Months</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Profit%</label>
                                            <input type="text" className="form-control" name="percentage" onBlur={handleInputBlur} placeholder='profit'  id="heading" defaultValue={data?.percentage} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Instant</label>
                                            <select className="form-control" onBlur={handleInputBlur} name="instant" id="role" >
                                                <option selected disabled>select</option>
                                                <option value="1">Daily</option>
                                                <option value="2">After</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Minimum Amount</label>
                                            <input type="number" className="form-control" name="min_amount" onBlur={handleInputBlur} placeholder='min amount'  id="heading" min={0} defaultValue={data?.min_amount} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">maximum Amount</label>
                                            <input type="text" className="form-control" name="max_amount" onBlur={handleInputBlur} placeholder='max amount'  id="heading" defaultValue={data?.max_amount} />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label htmlFor="heading" className="required">Expired Time</label>
                                            <input type="date" className="form-control" name="expired_time" onBlur={handleInputBlur} placeholder='expired_time'  id="heading" defaultValue={data?.expired_time}  />
                                        </div>


                                    </div>
                                  
                                </div>

    
                                    <div className="form-group">
                                        <button ref={refSubmitDis} type="submit" className="btn btn-primary w-100 h-45">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                </div>
            </>
        );

    }

    
};


export default MiningEdit;