import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar/SideBar';
import Header from './Header/Header';
import { ToastContainer } from 'react-toastify';
import AdminPrivateRoute from '../../Route/AdminPrivateRoute';
const HomeMain = () => {
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <AdminPrivateRoute>
                <div className="page-wrapper default-version">
                    <SideBar />
                    <Header />

                    <div className="body-wrapper">
                        <div className="bodywrapper__inner">

                            <Outlet></Outlet>


                        </div>
                    </div>
                </div>
            </AdminPrivateRoute>

        </>
    );
};

export default HomeMain;