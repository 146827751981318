import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink } from 'react-router-dom';


const SideBarSubMenu = ({ route, showAnimation, isOpen, setIsOpen, setMobileMenu }) => {
   
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsOpen(true);
    };

    useEffect(() => {
        if (!isOpen) {
            setIsMenuOpen(false);
        }
    }, [isOpen]);

    
    return (
        <>
            <div className="menu" onClick={toggleMenu}>
                <div className="menu_item">
                    <li className="sidebar-menu-item {{menuActive('admin.dashboard')}}">
                        <Link className="nav-link ">
                            <i className={route.icon}></i>
                            <div className=' d-flex justify-content-between align-items-center'>
                                <span className="menu-title">{route.name}
                                </span>
                                <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                            </div>
                        </Link>
                    </li>
                </div>
            </div>
            {isMenuOpen && (
                <div className="sidebar-submenu menuActive">
                    <ul>
                        {route.subRoutes.map((subRoute, i) => (
                            <li className="sidebar-menu-item menuActive" key={i}>
                                <li className="sidebar-menu-item menuActive">
                                    <NavLink to={subRoute.path} className="nav-link" onClick={()=>setMobileMenu(true)}>
                                        <i className="menu-icon las la-dot-circle"></i>
                                        <span className="menu-title">{subRoute.name}</span>
                                    </NavLink>
                                </li>
                            </li>
                        ))}
                    </ul>
                </div>
            )}{" "}
        </>
    );

};

export default SideBarSubMenu;